import {Typography, Box, Button} from "@mui/material";
import {FC, useCallback, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2";
import {useNavigate} from "react-router-dom";
import {IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizStartSummary: {
		width: "100%",
		textAlign: "center"
	},
	summaryTitle: {
	},
	summarySubtitle: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1)
	},
	summaryContent: {
		marginTop: theme.spacing(3)
	},
	summaryStart: {
		marginLeft: "auto",
		marginRight: "auto"
	}
}));

interface IProps {
	quiz?: IQuiz
}

export const QuizStartSummary: FC<IProps> = ({quiz}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	const handleStartAction = useCallback((): void => {
		navigate("active");
	}, [navigate]);

	useEffect(() => {
		if(quiz && quiz.complete)
			navigate("complete");
	}, [quiz])

	return (
		<Box className={classes.quizStartSummary}>
			{quiz?.title && (
				<Typography className={classes.summaryTitle} variant="h2">
					{quiz.title}
				</Typography>
			)}
			{quiz?.title && (
				<Typography className={classes.summarySubtitle} variant="h5">
					{quiz.subtitle}
				</Typography>
			)}
			<WYSIWYG className={classes.summaryContent}>
				{quiz?.content ?? ""}
			</WYSIWYG>
			<Button size="large" className={classes.summaryStart} onClick={handleStartAction}>
				Continue
			</Button>
		</Box>
	)
}
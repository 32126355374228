import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {QuizQuestionContainer} from "./QuizQuestion/QuizQuestionContainer";

const useStyles = makeStyles()((theme) => ({
	quizQuestions: {
		width: "100%"
	}
}));

export const QuizQuestions: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();

	return (
		<Box className={classes.quizQuestions}>
			<MarkQuizActionProvider>
				{({markAnswers, response}) =>
					<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
						{({quizQuestions}) =>
							<QuizQuestionContainer
								questions={quizQuestions}
								onSubmit={(answers) => markAnswers(courseSlug as string, quizSlug as string, answers)}
								submitResponseResultId={response}
							/>
						}
					</QuizQuestionsDataProvider>
				}
			</MarkQuizActionProvider>
		</Box>
	);
}
import {Box, Button, Typography} from "@mui/material";
import {IconContext, useApi} from "@plumeuk/shapeshift-identity";
import {FC, useContext} from "react";
import {makeStyles} from "tss-react/mui";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2";
import {ICourseLibraryBannerResponse} from "../../types/courseLibraryBanner";
import {Link} from "react-router-dom";

interface IStyleProps {
	backgroundUrl?: string,
	desktopContentWidthPx?: number,
	bannerImageMinimumHeightPx?: number
}

const useStyles = makeStyles<IStyleProps>()((theme, {backgroundUrl, bannerImageMinimumHeightPx}) => ({
	courseLibraryBanner: {
	},
	courseLibraryBannerInner: {
		borderRadius: theme.borderRadius.medium,
		padding: theme.spacing(5),
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4),
		background: backgroundUrl && `url('${backgroundUrl}') no-repeat`,
		backgroundSize: "cover",
		minHeight: bannerImageMinimumHeightPx ? `${bannerImageMinimumHeightPx}px` : "270px"
	},
	content: {
		color: theme.palette.text.secondary
	},
	courseLibraryBannerTitle: {
		maxWidth: "720px",
		marginBottom: theme.spacing(3)
	},
	bannerButton: {
		background: theme.palette.primary.dark,
		marginBottom: theme.spacing(1)
	}
}));

export const CourseLibraryBanner: FC = () => {
	const [{data: apiData}] = useApi<ICourseLibraryBannerResponse>("/api/course-library-banner?populate=backgroundImage");
	const bannerData = apiData?.data.attributes;
	const {classes} = useStyles({
		backgroundUrl: bannerData?.backgroundImage.data.attributes.url,
		desktopContentWidthPx: bannerData?.desktopContentWidthPx,
		bannerImageMinimumHeightPx: bannerData?.bannerImageMinimumHeightPx
	});

	const {icon} = useContext(IconContext);

	return (
		<Box className={classes.courseLibraryBanner}>
			{bannerData &&
				<Box className={classes.courseLibraryBannerInner}>
					<Typography className={classes.courseLibraryBannerTitle} variant="h2">{bannerData?.title ?? ""}</Typography>
					<WYSIWYG className={classes.content}>
						{bannerData?.content ?? ""}
					</WYSIWYG>
					{bannerData?.link &&
						<Button
							component={Link}
							to={bannerData.link}
							className={classes.bannerButton}
							endIcon={icon("arrowForwardIcon")}
							size="large"
						>
							Read more
						</Button>}
				</Box>
			}
		</Box>
	);
}
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {Button, Typography} from "@mui/material";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import MoodIcon from "@mui/icons-material/Mood";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate, useParams} from "react-router-dom";
import {ISurvey} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	centerTile: {
		position: "relative",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "#fff",
		width: "100%",
		minHeight: "500px",
		padding: "70px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 25px 40px 25px"
		}
	},
	title: {
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px",
			"& h2": {
				fontSize: "30px"
			},
			"& h5": {
				fontSize: "20px"
			}
		}
	},
	tagLine: {
	}
}));

interface IProps {
	survey?: ISurvey
}

export const SurveyCompletion: FC<IProps> = ({survey}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();
	const {courseSlug} = useParams();
	const surveyResponseSubmitted = (survey?.response?.length ?? 0) > 0;

	const nextButton = <Button endIcon={<PlayArrowIcon />} onClick={() => navigate("/course/" + courseSlug)}>
		Next
	</Button>;

	const retakeSurvey = <Button variant="outlined" endIcon={<EditIcon />} onClick={() => navigate("../active")}>
		{surveyResponseSubmitted ? "Update my answers" : "Take survey"}
	</Button>;

	return (
		<DynamicContainer noGutter={true}>
			<CenterTile
				className={classes.centerTile}
				title={surveyResponseSubmitted ? "Survey response submitted!" : "Survey skipped"}
				icon={surveyResponseSubmitted ? <MoodIcon /> : <SentimentDissatisfiedIcon />}
				buttons={[retakeSurvey, nextButton]}
			>
				<Typography variant="h6" className={classes.tagLine}>
					{surveyResponseSubmitted
						? "Thanks so much! If you would like to update your survey questions you can do so below, or proceed to the next module."
						: "No time, no problem! \nBut if you ever find yourself with a spare minute, we would love to know how you felt about the course."
					}
				</Typography>
				<br /><br />
			</CenterTile>
		</DynamicContainer>
	);
}
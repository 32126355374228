import {Button} from "@mui/material";
import {FC, useCallback, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import {WYSIWYG} from "@plumeuk/shapeshift-common/v2";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {OutlineButton} from "@plumeuk/shapeshift-common/buttons";
import {useNavigate, useParams} from "react-router-dom";
import {ISurvey} from "@plumeuk/shapeshift-types/ISurvey";
import {useSubmitSurveyResponse} from "@plumeuk/shapeshift-common/hooks";
import {CurriculumContext} from "../../../../contexts/curriculumContext";

const useStyles = makeStyles()((theme) => ({
	startBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		fontSize: "16px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
		"& p": {
			lineHeight: "34px",
			fontSize: "20px",
			[theme.breakpoints.down("sm")]: {
				lineHeight: "25px",
				fontSize: "18px"
			}
		}
	},
	icon: {
		color: theme.palette.secondary.main + "!important", //> R&D needed to remove important
		strokeWidth: "2px",
		stroke: "#fff"
	}
}));

interface IProps {
	survey?: ISurvey
}

export const SurveyStartSummary: FC<IProps> = ({survey}) => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: surveySlug} = useParams();
	const navigate = useNavigate();
	const {submitResponse, response} = useSubmitSurveyResponse();
	const {curriculumDispatch} = useContext(CurriculumContext);

	const handleStartAction = useCallback((): void => {
		navigate("active");
	}, [navigate]);

	const handleSkipAction = useCallback((): void => {
		if(!courseSlug || !surveySlug)
			return;

		submitResponse(courseSlug, surveySlug, [])
	}, [navigate, courseSlug, surveySlug]);

	useEffect(() => {
		if(!response || !surveySlug)
			return;

		curriculumDispatch({
			type: "setModuleComplete", module: {type: "survey", slug: surveySlug}, status: true})
	}, [response, surveySlug]);


	const startBtn = (
		<Button
			className={classes.startBtn}
			onClick={handleStartAction}
		>
			Continue
		</Button>
	);

	const skipBtn = (
		<OutlineButton
			className={classes.startBtn}
			onClick={handleSkipAction}
		>
			Skip
		</OutlineButton>
	);

	useEffect(() => {
		if(survey?.complete === true)
			navigate("complete");
	}, [survey])

	return (
		<CenterTile
			buttons={[skipBtn, startBtn]}
			icon={<FlashOnIcon className={classes.icon}/>}
			title={survey?.title}
			subtitle={survey?.subtitle}
		>
			<WYSIWYG className={classes.content}>
				{survey?.content ?? ""}
			</WYSIWYG>
		</CenterTile>
	)
}
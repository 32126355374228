import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {ResetPasswordForm, DynamicContainer, LoginNav} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	resetPassPage:{
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		minHeight: "100%"
	}
}));

export const ResetPasswordPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	const query = new URLSearchParams(useLocation().search)

	const code = query.get("code") ?? ""

	useEffect(() => {
		if(authenticated) navigate("/my-learning")
	}, [authenticated])

	return (
		<Box className={classes.resetPassPage}>
			<LoginNav
				onBackAction={() => navigate(-1)} // To do: temporary. Discuss where this goes
				onRegisterAction={() => navigate("register")}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				<ResetPasswordForm
					title="Confirm your new password"
					onLoginLinkClick={() => navigate("/")}
					code={code ?? ""}
				/>
			</DynamicContainer>
		</Box>
	);
}
import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {LoginForm, DynamicContainer, LoginNav} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";

const logo = "/v2Logo.svg"
const feather = "/PlumeFeather.svg"

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		minHeight: "100%"
	},
	imgFeather:{
		position: "fixed",
		bottom: "-10px",
		right: "-10px",
		pointerEvents: "none"
	}
}));

export const LoginPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if(authenticated) navigate("/my-learning")
	}, [authenticated])

	return (
		<Box className={classes.loginPage}>
			<LoginNav
				logo={logo}
				onBackAction={() => navigate(-1)} // To do: temporary. Discuss where this goes
				onRegisterAction={() => navigate("register")}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				<LoginForm
					title="Login to Plume Academy"
					onLogin={() => navigate("my-learning")}
					//onGoogleLoginAction={() => navigate("my-learning")}
					onForgotPasswordAction={() => navigate("forgotten-password")}
				/>
			</DynamicContainer>
			<Box className={classes.imgFeather}>
				<img src={feather} />
			</Box>
		</Box>
	);
}
import {Box, Button, Typography} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../../../../contexts/curriculumContext";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizResultSummary: {
		textAlign: "center"
	},
	quizResultContent: {
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(3)
	},
	quizResultBtn: {
		margin: theme.spacing(1)
	}
}));

interface IProps {
	quiz?: IQuiz,
	markedAnswers: IMarkedQuizAnswers,
	onCheckAnswersAction: () => void
}

export const QuizResultSummary: FC<IProps> = ({quiz, markedAnswers, onCheckAnswersAction}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const totalQuestions = (markedAnswers.correct + markedAnswers.incorrect);
	const {curriculumDispatch} = useContext(CurriculumContext);
	const accuracy = Math.round((markedAnswers.correct / totalQuestions) * 100);
	const navigate = useNavigate();

	useEffect(() => {
		if(!markedAnswers) return;
		if(!markedAnswers.pass){
			onCheckAnswersAction();
		}
		else {
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "quiz", slug: quiz?.slug as string}, status: true})
		}
	}, [markedAnswers, onCheckAnswersAction])

	return (
		<Box className={classes.quizResultSummary}>
			<Box>
				<Typography variant="h1">{`You passed ${quiz?.title}${quiz?.title.charAt(quiz?.title.length-1) === "!" ? "" : "!"}`}</Typography>
				<Typography variant="h5" className={classes.quizResultContent}>
					You scored {accuracy}% which is a pass.
				</Typography>
				<Typography variant="h5" className={classes.quizResultContent}>
					You can proceed to the next module{accuracy < 100 ? "," : " or"} review you answers
					{accuracy < 100 && " or retake the quiz"}.
				</Typography>
				<Box>
					<Button size="large" className={classes.quizResultBtn} onClick={() => navigate("/course/" + courseSlug)}>Next module</Button>
					<Button size="large" className={classes.quizResultBtn} variant="outlined" onClick={onCheckAnswersAction}>Check answers</Button>
					{accuracy < 100 &&
						<Button size="large" className={classes.quizResultBtn} variant="outlined" onClick={() => navigate("../active")}>Retake quiz</Button>
					}
				</Box>
			</Box>
		</Box>
	);
}
import {CourseTable} from "@plumeuk/shapeshift-common/courses";
import {useNavigate} from "react-router-dom";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
	},
	dashboardContainer: {
		paddingTop: theme.spacing(5)
	}
}));

export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<DynamicContainer>
				<CourseTable
					className={classes.dashboardContainer}
					onGoToCourseAction={courseId => {navigate("/course/" + courseId)}}
					onGoToCertificateAction={courseId => {navigate(`/course/${courseId}/complete`)}}
				/>
			</DynamicContainer>
		</PageBase>
	);
}
import {Button, Typography} from "@mui/material";
import {CourseCertificate} from "@plumeuk/shapeshift-common/course";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import Confetti from "react-confetti"

const useStyles = makeStyles()((theme) => ({
	tagLine: {
		color: "#2A2A2F",
		fontWeight: 400
	},
	dashBoardBtn: {},
	confetti: {
		width: "100vw",
		height: `calc(100vh - ${theme.toolbarHeight}px)`
	},
	file: {
		textAlign: "left",
		margin: "30px 0",
		[theme.breakpoints.down("sm")]: {
			background: "#BCBCBD33",
			textAlign: "inherit",
			"& > div:first-child": {
				display: "none"
			},
			"& h6": {
				fontSize: "22px"
			},
			"& [class*='downloadButton']": {
				borderTop: "solid 2px #d1d1d180"
			}
		}
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();

	const goToDashboardBtn = <Button onClick={() => navigate("/my-learning")} className={classes.dashBoardBtn}>Go to dashboard</Button>;

	return (
		<>
			<CenterTile
				title="Congratulations"
				icon={<WorkspacePremiumOutlinedIcon />}
				buttons={[goToDashboardBtn]}
			>
				<Typography variant="h6" className={classes.tagLine}>
					You finished the course and you have been awarded with a certificate. You can download it from the block below.
				</Typography>
				<CourseCertificate courseSlug={courseSlug} />

			</CenterTile>
			<Confetti className={classes.confetti} />
		</>
	);
}
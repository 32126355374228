import {QuizDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";

import {QuizRouter} from "./QuizRouter";

export const QuizPage: FC = () => {
	const {courseSlug, moduleSlug: quizSlug} = useParams();
	return (
		<DynamicContainer noGutter={true} size="constrained" align="center">
			<QuizDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
				{({quiz}) => <QuizRouter quiz={quiz}/>}
			</QuizDataProvider>
		</DynamicContainer>
	);
}
import {BreakpointsOptions} from "@mui/material";

export const salesBarWidth = "380px";
export const sideMenuWidth = "420px";
export const toolbarHeight = "70px";

//USER INPUT
interface IColor {
	light: string,
	main: string,
	dark: string
}

interface IColorPallete {
	primary: IColor,
	secondary: IColor,
	error: IColor,
	success: IColor,
	background: {
		default: string,
		paper: string,
		dark: string
	},
	text: {
		primary: string,
		secondary: string,
		light: string,
		gray: string,
		disabled?: string
	}
}

export const templatePalette: IColorPallete = {
	primary: {
		light: "#E7E7E9",
		main: "#0D0C22",
		dark: "#2A2A2F"
	},
	secondary: {
		light: "#F2EEFF",
		main: "#7856FF",
		dark: "#5a3bd9"
	},
	error: {
		light: "#EA4335",
		main: "#EA4335",
		dark: "#EA4335"
	},
	success: {
		light: "#99D3A9",
		main: "#24CD51",
		dark: "#12a339"
	},
	background: {
		default: "#FFFFFF",
		paper: "#F8F7FF",
		dark: "#0D0C22"
	},
	text: {
		primary: "#0D0C22",
		secondary: "#3D3D4E",
		light: "#FFFFFF",
		gray: "#9E9EA7"
	}
}

export const templateSpacing = {
	spacer: 0.50 //Spacing factor | 0.50 increments spacing by 8px
}

export const templateBreakpoints: BreakpointsOptions = {
	//Mobile  |		 x < sm
	//Tablet  |	sm < x < md
	//Desktop |		 x > md
	values: {
		// extra-small
		xs: 500,
		// small
		sm: 769,
		// medium
		md: 992,
		// large
		lg: 1200,
		// extra-large
		xl: 1440
	}
};

//Other theme constants
export const templateConstants = {
	containers: {
		constrained: "860px",
		standard: "1040px",
		wide: "1420px"
	},
	borderRadius: {
		small: "8px",
		medium: "16px",
		large: "100px"
	},
	thumbnails: {
		small: "150px",
		medium: "233px",
		large: "400px"
	},
	borderWidth: {
		small: "1px",
		medium: "2px",
		large: "4px"
	},
	iconSizes: {
		small: "10px",
		medium: "14px",
		large: "16px",
		extraLarge: "24px"
	},
	constants: {
		salesBarWidth: "380px",
		sideMenuWidth: "420px",
		toolbarHeight: "70px"
	},
	position: {
		absoluteCenter: {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)"
		}
	},
	boxShadow: {
		small: `0px 0px ${templateSpacing.spacer * 2}rem 0px rgba(0,0,0,0.07)`,
		medium: `0px 0px ${templateSpacing.spacer * 4}rem 0px rgba(0,0,0,0.07)`,
		large: `0px 0px ${templateSpacing.spacer * 6}rem 0px rgba(0,0,0,0.07)`
	},
	transitionSpeeds: {
		fast: "0.15s ease-in-out",
		medium: "0.35s ease-in-out",
		slow: "0.5s ease-in-out"
	}
}

//Transitions, animations & effects

export const templateTransitions = {
	hoverCard: {
		default: {
			transition: `${templateConstants.transitionSpeeds.fast}`
		},
		active: {
			cursor: "pointer",
			transform: `translateY(-${templateSpacing.spacer * 0.75}rem)`,
			opacity: "0.85",
			transition: `${templateConstants.transitionSpeeds.fast}`
		}
	},
	hoverFade: {
		default: {
			transition: `${templateConstants.transitionSpeeds.fast}`
		},
		active: {
			cursor: "pointer",
			opacity: "0.85",
			transition: `${templateConstants.transitionSpeeds.fast}`
		}
	},
	fadeAccordion: {
		default: {
			opacity: 0,
			transition: `${templateConstants.transitionSpeeds.fast}`
		},
		active: {
			opacity: 1,
			transition: `${templateConstants.transitionSpeeds.fast}`,
			transitionDelay: `opacity ${templateConstants.transitionSpeeds.fast}`
		}
	},
	expandAccordion: {
		default: {
			maxHeight: 0,
			opacity: 0,
			transition: `${templateConstants.transitionSpeeds.fast}`,
			overflow: "hidden"
		},
		active: {
			maxHeight: `${templateSpacing.spacer * 50}rem`,
			opacity: 1,
			transition: `${templateConstants.transitionSpeeds.medium}`,
			transitionDelay: `opacity ${templateConstants.transitionSpeeds.medium}`
		}
	}
}

interface ITypographyFontSizeProfile {
	default: string,
	mobile?: string
}

export interface ITypography {
	fonts: {
		primary: string,
		secondary?: string,
	},
	weights: {
		light: string,
		normal: string,
		semiBold: string,
		bold: string,
	},
	sizes: {
		h1: ITypographyFontSizeProfile,
		h2: ITypographyFontSizeProfile,
		h3: ITypographyFontSizeProfile,
		h4: ITypographyFontSizeProfile,
		h5: ITypographyFontSizeProfile,
		h6: ITypographyFontSizeProfile,
		paragraph: ITypographyFontSizeProfile,
		subtitle1: ITypographyFontSizeProfile,
		subtitle2: ITypographyFontSizeProfile,
		button: ITypographyFontSizeProfile,
		caption: ITypographyFontSizeProfile,
		highlight: ITypographyFontSizeProfile,
		small: ITypographyFontSizeProfile
	},
	lineHeights: {
		primary: number,
		secondary: number,
		caption: number
	}
}

export const templateTypography: ITypography = {
	fonts: {
		primary: "Roobert, sans-serif"
	},
	weights: {
		light: "300",
		normal: "400",
		semiBold: "500",
		bold: "600"
	},
	sizes: {
		h1: {default: "3.052rem"},
		h2: {default: "2.441rem"},
		h3: {default: "1.953rem"},
		h4: {default: "1.563rem"},
		h5: {default: "1.25rem"},
		h6: {default: "1.1rem"},
		subtitle1: {default: "18px"},
		subtitle2: {default: "14px"},
		caption: {default: "1rem"},
		paragraph: {default: "1.1rem"},
		button: {default: "18px"},
		highlight: {default: "1rem"},
		small: {default: "11.4px"}
	},
	lineHeights: {
		primary: 1.5,
		secondary: 1.375,
		caption: 1.175
	}

}
import {Box} from "@mui/material";
import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate} from "react-router-dom";
import {ForgottenPasswordForm, DynamicContainer, LoginNav} from "@plumeuk/shapeshift-common/v2";

const logo = "/v2Logo.svg"
const feather = "/PlumeFeather.svg"

const useStyles = makeStyles()((theme) => ({
	forgottenPassPage:{
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		minHeight: "100%"
	},
	imgFeather:{
		position: "fixed",
		bottom: "-10px",
		right: "-10px",
		pointerEvents: "none"
	}
}));

export const ForgottenPasswordPage: FC = () => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	return (
		<Box className={classes.forgottenPassPage}>
			<LoginNav
				logo={logo}
				onBackAction={() => navigate(-1)}
				onRegisterAction={() => navigate("register")}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				<ForgottenPasswordForm
					title="Password Recovery"
				/>
			</DynamicContainer>
			<Box className={classes.imgFeather}>
				<img src={feather} />
			</Box>
		</Box>
	);
}
import {FC, useState} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {ModuleTabs, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";

const useStyles = makeStyles()((theme) => ({
	tabs: {
		marginBottom: "50px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	activeTab: {
		color: "#2A2A2F !important"
	}
}));

export const WebinarPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: webinarSlug} = useParams();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");

	return (
		<DynamicContainer>
			<ModuleTabs moduleTabs={[{label: "Overview", value: "overview"}, {label: "Comments", value: "comments"}, {label: "Notes", value: "notes"}]} tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} />
			{tabSelected === "overview" && <>
				This will be the Webinar Page {webinarSlug} under course {courseSlug}
			</>}
			{tabSelected === "comments" && <CommentsSection moduleType={"webinar"} moduleSlug={webinarSlug}/>}
			{tabSelected === "notes" && <NotesSection moduleType="webinar" moduleSlug={webinarSlug} />}
		</DynamicContainer>
	);
}
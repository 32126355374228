import {Box, Button, Typography} from "@mui/material";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MarkedQuestion, MarkedQuestionsSummary} from "@plumeuk/shapeshift-common/v2";
import {FC, useContext} from "react";
import {IconContext} from "@plumeuk/shapeshift-identity";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		width: "100%",
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(10)
	},
	markedAnswer: {
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(7)
	},
	quizBreakdownTitle: {
		marginTop: theme.spacing(6)
	},
	quizBreakdownSubtitle: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(6)
	},
	markedLabel: {
		color: theme.palette.text.secondary
	},
	subTitle: {
		marginBottom: theme.spacing(4)
	}
}));

interface IProps {
	quiz?: IQuiz,
	markedAnswers: IMarkedQuizAnswers
}

export const QuizResultBreakdown: FC<IProps> = ({quiz, markedAnswers}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const mq = useMq();
	const navigate = useNavigate();

	const {icon} = useContext(IconContext);

	return (
		<Box className={classes.quizResult}>
			<MarkedQuestionsSummary markedAnswers={markedAnswers}/>
			{!mq.mobile ? <>
				<Typography variant="h2" className={classes.quizBreakdownTitle}>{quiz?.title}</Typography>
				<Typography variant="h5" className={classes.quizBreakdownSubtitle}>Pass by scoring {markedAnswers.requiredScore}%</Typography>
			</> : <Box sx={{marginBottom: "40px"}}/>}
			{markedAnswers?.markedAnswers?.map((answer, i) => (
				<Box key={`marked-answer-${i}`} className={classes.markedAnswer}>
					<Typography variant="caption" className={classes.markedLabel}>Question {i+1} of {markedAnswers.markedAnswers.length}</Typography>
					<MarkedQuestion markedAnswer={answer}/>
				</Box>
			))}
			<Button
				size="large"
				onClick={() => markedAnswers.pass ? navigate("/course/" + courseSlug) : navigate("../active")}
				endIcon={icon("arrowForwardIcon")}
			>
				{markedAnswers.pass ? "Next module" : "Retake"}
			</Button>
		</Box>
	);
}
import {Box, BoxProps} from "@mui/material";
import React, {FC, useEffect, useState, isValidElement, cloneElement, ReactNode} from "react";
import {NavBar, SideMenu} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";

const logo = "/PlumeLogo.svg"

interface ICustomProps {
	children: React.ReactNode,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean,
	disableSideBarOpen?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar}) => ({
	pageBase: {
		height: "100%"
	},
	sideMenu: {
		backgroundColor: theme.palette.background.default,
		"& ::-webkit-scrollbar": {
			display: "none"
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.constants.toolbarHeight
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${theme.constants.sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? theme.constants.sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		top: hasNavBar ? theme.constants.toolbarHeight : 0,
		height: hasNavBar ? `calc(100% - ${theme.constants.toolbarHeight})` : "100%",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			left: 0
		}
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "My Learning" | "Library";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My Learning": "/my-learning",
	"Library": "/library"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, children, className, ...props}) => {
	const mq = useMq();
	const pageIsMyLearning = useMatch("/my-learning");
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: !mq.mobile || !pageIsMyLearning});
	const navigate = useNavigate();

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>

			<NavBar<pages>
				tabs={["My Learning", "Library"]}
				onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				onPageSelect={handlePageSelect}
				pageSelected={pageSelected}
				logoPath={logo}
				disableSideBar={disableSideBar}
				editUserDetailsForm={EditUserDetailsForm}
			/>
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
			>
				{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{children}
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};

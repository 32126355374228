import {SurveyDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC, useContext} from "react";
import {useParams} from "react-router-dom";
import {DynamicContainer} from "@plumeuk/shapeshift-common/v2";

import {SurveyRouter} from "./SurveyRouter";
import {CurriculumContext} from "../../../contexts/curriculumContext";

export const SurveyPage: FC = () => {
	const {courseSlug, moduleSlug: survey} = useParams();
	const {curriculumState: curriculum} = useContext(CurriculumContext);

	return (
		<DynamicContainer>
			<SurveyDataProvider courseSlug={courseSlug} surveySlug={survey} curriculum={curriculum}>
				{({survey}) => <SurveyRouter survey={survey}/>}
			</SurveyDataProvider>
		</DynamicContainer>
	);
}